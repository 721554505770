/*
 * @Author: 小王最强 wq207430@163.com
 * @Date: 2022-12-05 10:36:52
 * @LastEditors: 小王最强 wq207430@163.com
 * @LastEditTime: 2023-11-06 16:52:23
 * @FilePath: /CRM2.0-VUE/src/utils/axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * @Author: 李明
 * @Date: 2021-11-04 09:07:36
 * @LastEditTime: 2022-05-06 13:56:35
 * @LastEditors: 李明
 * @Description:
 * @FilePath: /CRM2.0-VUE/src/utils/axios.js
 * @@山东壹零贰肆信息科技有限公司
 */
import router from '@/router'
import axiUrl from 'axios'
import { MessageBox } from 'element-ui'
import baseSURL from '@/api/basesURL'
const urls = {
  // 华鑫线上
  'hx-shang': baseSURL.hxBaseURL,
  // 国邦盛世
  'gy-shang': baseSURL.gyBaseURL,
  // 锦程线上
  'jc-shang': baseSURL.jcBaseURL,
  // 丰石
  'zq-xia': baseSURL.fsBaseURL,
  // 本地测试
  'jc-xia': baseSURL.bdBaseURL,
  // 本地测试
  'jc-ceshi': baseSURL.ceshiBaseURL,
  // 济南丰晟
  'jn-fs': baseSURL.jnfsBaseURL,
  // 天成投研
  'tc-shang': baseSURL.tcBaseURL,
  // 本地电脑测试
  'local-host': baseSURL.localHostURL,
  // 中炎信息
  'jc-zyxx': baseSURL.zyxxBaseURL,
  // 逸盛
  'ys-shang': 'https://api.10010.1024xxkj.com/api/'
}
const instance = axiUrl.create({
  baseURL: urls[process.env.VUE_APP_URL]
})
// 添加默认请求头
// instance.defaults.baseURL = 'https://api.yizhaobiao.cn/';
instance.interceptors.request.use(config => {
  config.headers = {
    'Content-Type': 'application/json',
    token: localStorage.getItem('token'),
    channel: '1',
    version: '1'
  }
  return config
})
let flg = true
// // 响应拦截器
instance.interceptors.response.use(function(response) {
  if (response.data.code == 400 && flg) {
    flg = false
    MessageBox.confirm('登录状态已过期，您需要重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      type: 'warning'
    }).then(() => {
      localStorage.removeItem('token')
      router.push('/login')
      flg = true
    })
  } else {
    return response
  }
})

export default instance
