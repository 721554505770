/**
 * @Author: 李明
 * @Date: 2021-11-05 14:37:54
 * @LastEditTime: 2022-04-11 10:57:43
 * @LastEditors: 李明
 * @Description:
 * @FilePath: \项目\后台项目\src\api\axios.js
 * @@山东壹零贰肆信息科技有限公司
 */
import { Message } from 'element-ui'
import store from '../store/index'
// 判定用户权限是否展示
function computer(nums) {
  if (localStorage.getItem('token')) {
    return store.getters.computer.indexOf(nums) != -1
  } else {
    return store.getters.computer.indexOf(nums) == -1
  }
}
// 将树状图下的数据的某一项取出来
function extractTree(nodes, result, parent_value) {
  let list = result || []
  let valueField = 'id'
  for (let i = 0; i < nodes.length; i++) {
    let node = nodes[i]
    let child = {}
    for (let key in node) {
      if (key !== 'treeList') {
        child[key] = node[key]
      }
    }
    if (
      parent_value !== null &&
      parent_value !== undefined &&
      parent_value !== ''
    ) {
      child.parent_value = parent_value
    }
    result.push(child.id)
    let treeList = node.treeList
    if (treeList) {
      extractTree(treeList, result, node[valueField])
    }
  }
}
// 判定用户的权限是否显示
// function judge(num) {
//   if (localStorage.getItem('token')) {
//     return store.getters.operation.indexOf(num) != -1
//   } else {
//     return store.getters.operation.indexOf(num) == -1
//   }
// }
// 随机字母
function getRanNum() {
  var result = []
  for (var i = 0; i < 2; i++) {
    var ranNum = Math.ceil(Math.random() * 25) //生成一个0到25的数字
    //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
    result.push(String.fromCharCode(65 + ranNum).toLowerCase())
  }
  return result.join('')
}
// 汉字转换字母
function createpy(num) {
  let pinyin = require('js-pinyin')
  pinyin.setOptions({
    checkPolyphone: false,
    charCase: 0
  })
  return pinyin.getFullChars(num).toLowerCase()
}
// 当前日期
function getNowTime() {
  var now = new Date()
  var year = now.getFullYear() // 得到年份
  var month = now.getMonth() // 得到月份
  var date = now.getDate() // 得到日期
  month = month + 1
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')

  var defaultDate = `${year}-${month}-${date}`
  return defaultDate
}
// 当月第一天
function getCurrentMonthFirst() {
  var date = new Date()

  date.setDate(1)

  var months = parseInt(date.getMonth() + 1)

  var days = date.getDate()

  if (months < 10) {
    months = '0' + months
  }

  if (days < 10) {
    days = '0' + days
  }

  return date.getFullYear() + '-' + months + '-' + days
}
// 当前日期时分秒
function getNow() {
  var now = new Date()
  var year = now.getFullYear() // 得到年份
  var month = now.getMonth() // 得到月份
  var date = now.getDate() // 得到日期
  month = month + 1
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')
  let hh = new Date().getHours()
  let mf =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes()
  let ss =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds()
  var defaultDate = `${year}-${month}-${date} ${hh}:${mf}:${ss}`
  return defaultDate
}

// 添加数组数据
function getArr(row, value) {
  if (row.length != 0) {
    for (let i = 0; i < row.length; i++) {
      if (value.find(j => j == row[i]) == undefined) {
        value.push(row[i].id == undefined ? row[i] : row[i].id)
      }
    }
  }
}
// 循环乘以参数
function parameter(row) {
  if (row.length != 0) {
    for (let i = 0; i < row.length; i++) {
      row[i].money = row[i].money * 10000
    }
    return row
  }
}
// 循环除以参数
function divide(row) {
  if (row.length != 0) {
    for (let i = 0; i < row.length; i++) {
      row[i].money = row[i].money / 10000
    }
    return row
  }
}

// 滚动到表单验证不通过的地方
function scrollView() {
  let isError = document.getElementsByClassName('is-error')
  if (isError[0] != undefined) {
    isError[0].scrollIntoView({
      // 滚动到指定节点
      // 值有start,center,end，nearest，当前显示在视图区域中间
      block: 'center',
      // 值有auto、instant,smooth，缓动动画（当前是慢速的）
      behavior: 'smooth'
    })
    return
  }
}
//打开关闭搜索按钮
function handleChange(val, num) {
  let key = val
  if (!key.style.height || key.style.height == '0px') {
    key.style.height = num
  } else {
    key.style.height = '0px'
  }
}
// 解析后台返回的二进制流，成为文件
function exportExcel(data, name) {
  let blob = new Blob([data], { type: 'application/vnd.ms-excel' }) // 设置文件类型excel
  let url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
  // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
  let a = document.createElement('a')
  a.href = url
  a.download = name + '.xlsx'
  a.click()
  // 释放这个临时的对象url
  window.URL.revokeObjectURL(url)
}
//设置cookie

function setCookie(name, value, hours) {
  let str = name + '=' + value
  const time = new Date(
    new Date().getTime() + hours * 3600 * 1000
  ).toGMTString() // toGMTstring将时间转换成字符串
  str += '; expires=' + time
  // 写入Cookie
  document.cookie = str
}

// 第一个参数为对象，用键值对的形式传入我们想设置的cookie名和值
// 第二个参数为过期时间，单位为天
// setCookies({
// 	name: '张三',
//     age: 19,
//     like: '羽毛球'
//  }, 7)
//查看cookie
function getCookie(name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)'),
    arr = document.cookie.match(reg)
  if (arr !== null) {
    return arr[2]
  } else {
    return null
  }
}
// 该函数只有一个参数，即我们需要查询的cookie名称，然后返回一个值
// let cookieValue = searchCookie(name)

/**
 * 清除cookie
 */
function clearCookie(name) {
  setCookie(name, '', -1)
}
//该函数只有一个参数，传入一个数组，每个元素为我们想要删除的cookie名
// removeCookies(['name', 'age'])
// 获取当前时间 距离0点还有多久 (秒)
function timeIng() {
  var x = new Date()
  x.setHours(0, 0, 0, 0)
  var y = new Date()
  let key = 24 * 3600 * 1000 - (y.getTime() - x.getTime())
  return key + 10
}
// 将数据串转成base64格式
function getBase64(file) {
  return new Promise(function(resolve, reject) {
    let reader = new FileReader()
    let imgResult = ''
    reader.readAsDataURL(file)
    reader.onload = function() {
      imgResult = reader.result
    }
    reader.onerror = function(error) {
      reject(error)
    }
    reader.onloadend = function() {
      resolve(imgResult)
    }
  })
}
// 数据转成 formData格式
function objTurnForm(data) {
  let form = new FormData()
  for (let i in data) form.append(i, data[i])
  return form
}

function install(Vue) {
  // 防止重复点击
  Vue.directive('preventReClick', {
    inserted(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true
          setTimeout(() => {
            el.disabled = false
          }, binding.value || 1000)
        }
      })
    }
  })
}
//base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
function dataURLtoFile(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], { type: mime })
}
//压缩图片
function compressImg(file) {
  var src
  var files
  var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2)
  var read = new FileReader()
  read.readAsDataURL(file)
  return new Promise(function(resolve, reject) {
    read.onload = function(e) {
      var img = new Image()
      img.src = e.target.result
      img.onload = function() {
        //默认按比例压缩
        var w = this.width,
          h = this.height
        //生成canvas
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        var base64
        // 创建属性节点
        canvas.setAttribute('width', w)
        canvas.setAttribute('height', h)
        ctx.drawImage(this, 0, 0, w, h)
        if (fileSize < 1) {
          //如果图片小于一兆 那么不执行压缩操作
          base64 = canvas.toDataURL(file['type'], 1)
        } else if (fileSize > 1 && fileSize < 2) {
          //如果图片大于1M并且小于2M 那么压缩0.5
          base64 = canvas.toDataURL(file['type'], 0.5)
        } else {
          //如果图片超过2m 那么压缩0.2
          base64 = canvas.toDataURL(file['type'], 0.2)
        }
        // 回调函数返回file的值（将base64编码转成file）
        files = dataURLtoFile(base64) //如果后台接收类型为base64的话这一步可以省略

        resolve(files)
      }
    }
  })
}
export default {
  computer,
  getRanNum,
  createpy,
  getNowTime,
  getNow,
  getArr,
  scrollView,
  handleChange,
  exportExcel,
  parameter,
  divide,
  setCookie,
  getCookie,
  clearCookie,
  timeIng,
  extractTree,
  getBase64,
  objTurnForm,
  compressImg,
  getCurrentMonthFirst
  //消息提示框
  // promptBox: function (title, status) {
  //     Message({
  //         showClose: true,
  //         message: title,
  //         type: status
  //     });
  // }
}
