<!--
 * @Author: 李明
 * @Date: 2021-10-20 13:49:40
 * @LastEditTime: 2023-01-03 09:50:56
 * @LastEditors: 小王最强 wq207430@163.com
 * @Description: 
 * @FilePath: \项目\锦程Crm\锦程crm\src\App.vue
 * @山东壹零贰肆信息科技有限公司
-->
<template>
  <div id="app">
    <router-view v-if="isRouter"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouter: true
    }
  },
  methods: {
    reload() {
      this.isRouter = false
      this.$nextTick(() => {
        this.isRouter = true
      })
    }
  }
  // created() {
  //   document.addEventListener('visibilitychange', function() {
  //     console.log(11111)
  //     // if (store.getters.token != getToken()) {
  //     //   location.reload()
  //     // }
  //   })
  // }
}
</script>

<style lang="less">
body .el-table::before {
  z-index: inherit;
}
</style>
