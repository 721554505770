import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout'
// import store from '@/store'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: 'login',
      children: [
        {
          name: '首页',
          path: 'index',
          component: () => import('@/views/index'),
          meta: {
            closable: true
          }
        },
        {
          name: '架构管理',
          path: 'Branch',
          component: () => import('@/views/Deptpage/Branch'),
          meta: {
            closable: true,
            activeMenu: '/Branch'
          }
        },
        {
          name: '人员管理',
          path: 'dept',
          component: () => import('@/views/Deptpage/Dept'),
          meta: {
            closable: true,
            activeMenu: '/dept'
          }
        },
        {
          name: '员工审核',
          path: 'employeeReview',
          component: () => import('@/views/Deptpage/employeeReview'),
          meta: {
            closable: true
          }
        },
        {
          name: '角色管理',
          path: 'jueSe',
          component: () => import('@/views/Deptpage/jueSe'),
          meta: {
            closable: true
          }
        },
        {
          name: '账户管理',
          path: 'household',
          component: () => import('@/views/Deptpage/household'),
          meta: {
            closable: true
          }
        },
        {
          name: '职级管理',
          path: 'Rankmanagement',
          component: () => import('@/views/Deptpage/Rankmanagement'),
          meta: {
            closable: true
          }
        },
        {
          name: '职级评分',
          path: 'rankscore',
          component: () => import('@/views/Deptpage/rankscore'),
          meta: {
            closable: true
          }
        },
        {
          name: '业绩管理',
          path: 'performancelist',
          component: () => import('@/views/Deptpage/performancelist'),
          meta: {
            closable: true
          }
        },
        {
          name: '个人客户',
          path: 'MineUser',
          component: () => import('@/views/userPage/MineUser'),
          meta: {
            closable: true
          }
        },
        {
          name: '客户管理',
          path: 'BranchUser',
          component: () => import('@/views/userPage/BranchUser'),
          meta: {
            closable: true
          }
        },
        {
          name: '公海管理',
          path: 'Highmanagement',
          component: () => import('@/views/userPage/Highmanagement'),
          meta: {
            closable: true
          }
        },
        {
          name: '订单初审',
          path: 'OrderAuditOne',
          component: () => import('@/views/Order/OrderAuditOne'),
          meta: {
            closable: true
          }
        },
        {
          name: '常规复审',
          path: 'OrderAuditTwo',
          component: () => import('@/views/Order/OrderAuditTwo'),
          meta: {
            closable: true
          }
        },
        {
          name: '财务复审',
          path: 'AuditPay',
          component: () => import('@/views/Order/AuditPay'),
          meta: {
            closable: true
          }
        },

        {
          name: '团队订单',
          path: 'Orderlist',
          // component: () => import('@/views/Order/Orderlist'),
          component: () => import('@/views/Order/LMOrderList'),
          meta: {
            closable: true
          }
        },
        {
          name: '个人订单',
          path: 'Individualorder',
          component: () => import('@/views/Order/Individualorder'),
          meta: {
            closable: true
          }
        },
        {
          name: '待审核订单(个人)',
          path: 'OrderIndividual',
          component: () => import('@/views/Order/OrderIndividual'),
          meta: {
            closable: true
          }
        },

        {
          name: '待审核订单(团队)',
          path: 'OrderReviewedTeam',
          component: () => import('@/views/Order/OrderReviewedTeam'),
          meta: {
            closable: true
          }
        },
        {
          name: '待修改订单(个人)',
          path: 'PersonalOrder',
          component: () => import('@/views/Order/PersonalOrder'),
          meta: {
            closable: true
          }
        },
        {
          name: '待修改订单(团队)',
          path: 'Rejectorder',
          component: () => import('@/views/Order/Rejectorder'),
          meta: {
            closable: true
          }
        },
        {
          name: '草稿箱',
          path: 'drafts',
          component: () => import('@/views/Order/drafts'),
          meta: {
            closable: true
          }
        },
        {
          name: '财务申请',
          path: 'FinancialView',
          component: () => import('@/views/financial/FinancialView'),
          meta: {
            closable: true
          }
        },
        {
          name: '财务审核',
          path: 'FinancialAuditView',
          component: () => import('@/views/financial/FinancialAuditView'),
          meta: {
            closable: true
          }
        },
        {
          name: '待付款',
          path: 'FinancialWaitPay',
          component: () => import('@/views/financial/FinancialWaitPay'),
          meta: {
            closable: true
          }
        },
        {
          name: '付款中',
          path: 'FinancialPaying',
          component: () => import('@/views/financial/FinancialPaying'),
          meta: {
            closable: true
          }
        },
        {
          name: '已付款',
          path: 'FinancialPaid',
          component: () => import('@/views/financial/FinancialPaid'),
          meta: {
            closable: true
          }
        },
        {
          name: '财务入账',
          path: 'entry',
          component: () => import('@/views/financial/entry'),
          meta: {
            closable: true
          }
        },
        {
          name: '短信模板管理',
          path: 'smsTemplate',
          component: () => import('@/views/marketing/smsTemplate'),
          meta: {
            closable: true
          }
        },
        {
          name: '营销短信模板管理',
          path: 'markesmsTemplate',
          component: () => import('@/views/marketing/markesmsTemplate'),
          meta: {
            closable: true
          }
        },
        {
          name: '用户标签管理',
          path: 'UserTags',
          component: () => import('@/views/marketing/UserTags'),
          meta: {
            closable: true
          }
        },
        {
          name: '短信充值管理',
          path: 'SMSRecharge',
          component: () => import('@/views/marketing/SMSRecharge'),
          meta: {
            closable: true
          }
        },
        {
          name: '短信记录',
          path: 'SMSrecord',
          component: () => import('@/views/Record/SMSrecord'),
          meta: {
            closable: true
          }
        },
        {
          name: '充值记录',
          path: 'rechargeRecord',
          component: () => import('@/views/marketing/rechargeRecord'),
          meta: {
            closable: true
          }
        },
        {
          name: 'OA申请',
          path: 'newOAapply',
          component: () => import('@/views/OAmanagement/OAapply'),
          meta: {
            closable: true
          }
        },
        {
          name: 'OA审批',
          path: 'newOAlist',
          component: () => import('@/views/OAmanagement/OAlist'),
          meta: {
            closable: true
          }
        },
        {
          name: 'OA模版',
          path: 'newOAtemplate',
          component: () => import('@/views/OAmanagement/OAtemplate'),
          meta: {
            closable: true
          }
        },

        {
          name: 'OA申请(旧)',
          path: 'OAApply',
          component: () => import('@/views/OAView/OAApply'),
          meta: {
            closable: true
          }
        },

        {
          name: 'OA审批(旧)',
          path: 'OAAudit',
          component: () => import('@/views/OAView/OAAudit'),
          meta: {
            closable: true
          }
        },
        {
          name: 'OA记录(旧)',
          path: 'OAsubmission',
          component: () => import('@/views/OAView/OAsubmission'),
          meta: {
            closable: true
          }
        },
        {
          name: 'OA模板管理(旧)',
          path: 'OATemplate',
          component: () => import('@/views/OAView/OATemplate'),
          meta: {
            closable: true
          }
        },
        {
          name: '模版列表',
          path: 'contractList',
          component: () => import('@/views/contract/contractList'),
          meta: {
            closable: true
          }
        },
        {
          name: '合同列表',
          path: 'hetongList',
          component: () => import('@/views/contract/hetongList'),
          meta: {
            closable: true
          }
        },
        {
          name: '机构管理',
          path: 'company',
          component: () => import('@/views/product/company'),
          meta: {
            closable: true
          }
        },
        {
          name: '公益项目',
          path: 'welfare',
          component: () => import('@/views/product/welfare'),
          meta: {
            closable: true
          }
        },
        {
          name: '转续规则',
          path: 'zhuanxurules',
          component: () => import('@/views/product/zhuanxurules'),
          meta: {
            closable: true
          }
        },
        {
          // name: '资产配置', // 济南丰晟专用
          name: '理财产品',
          path: 'classification',
          component: () => import('@/views/product/classification'),
          meta: {
            closable: true
          }
        },
        {
          name: '理财分类',
          path: 'ProductCategory',
          component: () => import('@/views/product/ProductCategory'),
          meta: {
            closable: true
          }
        },
        {
          name: '保险产品',
          path: 'insure',
          component: () => import('@/views/product/insure'),
          meta: {
            closable: true
          }
        },
        {
          name: '保险分类',
          path: 'insureclass',
          component: () => import('@/views/product/insureclass'),
          meta: {
            closable: true
          }
        },
        {
          name: '文章列表',
          path: 'article',
          component: () => import('@/views/Article/article'),
          meta: {
            closable: true
          }
        },
        {
          name: '事务性管理',
          path: 'workmanage',
          component: () => import('@/views/Article/workmanage'),
          meta: {
            closable: true
          }
        },
        {
          name: '协议列表',
          path: 'agreement',
          component: () => import('@/views/Article/agreement'),
          meta: {
            closable: true
          }
        },
        {
          name: '活动中心',
          path: 'activityCenter',
          component: () => import('@/views/Article/activityCenter'),
          meta: {
            closable: true
          }
        },
        {
          name: '实时新闻',
          path: 'newsList',
          component: () => import('@/views/Article/newsList'),
          meta: {
            closable: true
          }
        },
        {
          name: '签到信息',
          path: 'qiandao',
          component: () => import('@/views/integral/qiandao'),
          meta: {
            closable: true
          }
        },
        {
          name: '会员等级',
          path: 'leaver',
          component: () => import('@/views/integral/leaver'),
          meta: {
            closable: true
          }
        },
        {
          name: '积分商城',
          path: 'integral',
          component: () => import('@/views/integral/integral'),
          meta: {
            closable: true
          }
        },
        {
          name: '商城分类',
          path: 'integralclass',
          component: () => import('@/views/integral/integralclass'),
          meta: {
            closable: true
          }
        },
        {
          name: '商品订单',
          path: 'shoporderlist',
          component: () => import('@/views/integral/shoporderlist'),
          meta: {
            closable: true
          }
        },
        {
          name: '卡券模版',
          path: 'youhuiquan',
          component: () => import('@/views/integral/youhuiquan'),
          meta: {
            closable: true
          }
        },
        {
          name: '卡券记录',
          path: 'youhuiquanhistory',
          component: () => import('@/views/integral/youhuiquanhistory'),
          meta: {
            closable: true
          }
        },
        {
          name: '问题列表',
          path: 'questionList',
          component: () => import('@/views/questions/questionslist'),
          meta: {
            closable: true
          }
        },
        {
          name: '风险等级',
          path: 'questionitem',
          component: () => import('@/views/questions/risklevel'),
          meta: {
            closable: true
          }
        },
        {
          name: '轮播图列表',
          path: 'banner',
          component: () => import('@/views/Rotation/banner'),
          meta: {
            closable: true
          }
        },
        {
          name: '产品轮播图列表',
          path: 'productbanner',
          component: () => import('@/views/Rotation/productbanner'),
          meta: {
            closable: true
          }
        },
        {
          name: '审核记录',
          path: 'audithistory',
          component: () => import('@/views/Record/audithistory'),
          meta: {
            closable: true
          }
        },
        {
          name: '交接记录',
          path: 'workerchange',
          component: () => import('@/views/Record/workerchange'),
          meta: {
            closable: true
          }
        },
        {
          name: '订单记录',
          path: 'records',
          component: () => import('@/views/Record/records'),
          meta: {
            closable: true
          }
        },
        {
          name: '付款记录',
          path: 'Payment',
          component: () => import('@/views/Record/Payment'),
          meta: {
            closable: true
          }
        },

        {
          name: '系统记录',
          path: 'System',
          component: () => import('@/views/Record/System'),
          meta: {
            closable: true
          }
        },
        {
          name: '通知配置',
          path: 'noticeset',
          component: () => import('@/views/noticeset/noticeset'),
          meta: {
            closable: true
          }
        },
        {
          name: '通知记录',
          path: 'noticesetlist',
          component: () => import('@/views/noticeset/noticesetlist'),
          meta: {
            closable: true
          }
        },
        {
          name: '年度考核',
          path: 'yearcheck',
          component: () => import('@/views/statistics/yearcheck'),
          meta: {
            closable: true
          }
        },
        {
          name: '数据导出',
          path: 'exports',
          component: () => import('@/views/statistics/exports'),
          meta: {
            closable: true
          }
        },

        {
          name: '面板1',
          path: 'bigDataOne',
          component: () => import('@/views/statistics/statistics'),
          meta: {
            closable: true
          }
        },
        {
          name: '面板2',
          path: 'bigDataTwo',
          component: () => import('@/views/statistics/BigDataTwo'),
          meta: {
            closable: true
          }
        },
        {
          name: '异常打款',
          path: 'errmoney',
          component: () => import('@/views/statistics/errmoney'),
          meta: {
            closable: true
          }
        },
        {
          name: '异常客户',
          path: 'erruser',
          component: () => import('@/views/statistics/erruser'),
          meta: {
            closable: true
          }
        },
        {
          name: '异常银行卡',
          path: 'errbanck',
          component: () => import('@/views/statistics/errbanck'),
          meta: {
            closable: true
          }
        },
        {
          name: '异常订单',
          path: 'errorder',
          component: () => import('@/views/statistics/errorder'),
          meta: {
            closable: true
          }
        },
        {
          name: '导出记录',
          path: 'export',
          component: () => import('@/views/Record/export'),
          meta: {
            closable: true
          }
        },
        {
          name: '银行管理',
          path: 'bankManagement',
          component: () => import('@/views/system/bankManagement'),
          meta: {
            closable: true
          }
        },
        {
          name: '部门占比',
          path: 'branchRate',
          component: () => import('@/views/system/branchRate'),
          meta: {
            closable: true
          }
        },
        {
          name: '收益模拟',
          path: 'shouyimoney',
          component: () => import('@/views/system/tools/shouyimoney'),
          meta: {
            closable: true
          }
        },
        {
          name: '时间模拟',
          path: 'timeimitate',
          component: () => import('@/views/system/tools/timeimitate'),
          meta: {
            closable: true
          }
        },
        {
          name: '更改收益记录',
          path: 'changeremoneyhistry',
          component: () => import('@/views/system/tools/changeremoneyhistry'),
          meta: {
            closable: true
          }
        },
        {
          name: '节假日管理',
          path: 'vacation',
          component: () => import('@/views/system/vacation'),
          meta: {
            closable: true
          }
        },
        {
          name: '系统设置',
          path: 'SystemSettings',
          component: () => import('@/views/system/SystemSettings'),
          meta: {
            closable: true
          }
        },
        {
          name: '支行管理',
          path: 'SubBranch',
          component: () => import('@/views/system/SubBranch'),
          meta: {
            closable: true
          }
        },
        {
          name: '电签充值管理',
          path: 'enterpay',
          component: () => import('@/views/contract/enterpay'),
          meta: {
            closable: true
          }
        },
        {
          name: '印章管理',
          path: 'Enterprise',
          component: () => import('@/views/contract/Enterprise'),
          meta: {
            closable: true
          }
        },
        {
          name: '绩效管理',
          path: 'performance',
          component: () => import('@/views/performance'),
          meta: {
            closable: true
          }
        },
        // 空白页面
        {
          name: '空白页面',
          path: 'refresh',
          component: () => import('@/views/refresh'),
          meta: {
            closable: true
          }
        },
        // 空白页面
        {
          name: '空白页面',
          path: 'redirect',
          component: () => import('@/views/redirect'),
          meta: {
            closable: true
          }
        },
        {
          name: '主要客户页面',
          path: 'firstCustom',
          component: () => import('@/views/firstCustom'),
          meta: {
            closable: true
          }
        }
      ]
    },
    {
      name: '登录',
      path: '/login',
      component: () => import('@/views/Login')
    }
  ]
})
// 路由守卫
router.beforeEach((to, from, next) => {
  let isLogin = window.localStorage.getItem('token')
  if (!isLogin && to.path != '/login')
    next({
      name: '登录'
    })
  else next()
})

export default router
