/**
 * @Author: 李明
 * @Date: 2021-11-04 09:07:36
 * @LastEditTime: 2022-11-27 16:57:57
 * @LastEditors: 李明
 * @Description:
 * @FilePath: /CRM2.0-VUE/src/store/index.js
 * @@山东壹零贰肆信息科技有限公司
 */
import Vue from 'vue'
import Vuex from 'vuex'
import axiosMethods from '@/api/axios'

// import CreatePersistedstate from "vuex-persistedstate"
import { getToken } from '@/utils/token'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    token: getToken(),
    arr: [],
    jurisdiction: [],
    userId: '',
    // 标签页数据
    menuTabs: [
      {
        name: 'index',
        title: '首页'
      }
    ],
    // 重要客户
    firstCustom: {}
  },
  getters: {
    token: state => (state.token ? state.token : localStorage.getItem('token')),
    computer() {
      const num = []
      axiosMethods.extractTree(store.state.jurisdiction, num)
      return num
    }
  },
  mutations: {
    setToken: (state, token) => {
      localStorage.setItem('token', token)
      state.token = token
    },
    changeArr(state, value) {
      state.arr = value
    }
  }
  // plugins: [CreatePersistedstate()]
})

export default store
