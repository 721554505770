// 电话号码或者固话号码
export function validatePhoneTwo(rule, value, callback) {
  // const reg = /^1[3|4|5|7|8|6|9]\d{9}$/
  const reg = /^1\d{10}$/
  if (value == '' || value == undefined || value == null) {
    callback()
  } else {
    if (!reg.test(value) && value != '') {
      callback(new Error('请输入正确的电话号码'))
    } else {
      callback()
    }
  }
}
// 对公银行卡号正则表达式
export function Corporate(rule, value, callback) {
  const reg = /^[1-9]\d{7,29}$/
  if (value == '' || value == undefined || value == null) {
    callback()
  } else {
    if (!reg.test(value) && value != '') {
      callback(new Error('请输入正确的对公银行卡号'))
    } else {
      callback()
    }
  }
}
// 身份证号码验证
export function validateIdNo(rule, value, callback) {
  var checkCode = function(val) {
    var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
    var code = val.substring(17)
    if (p.test(val) && val != '') {
      var sum = 0
      for (var i = 0; i < 17; i++) {
        sum += val[i] * factor[i]
      }
      if (parity[sum % 11] == code.toUpperCase()) {
        callback()
      }
    } else if (val != '') {
      callback(new Error('请输入正确的身份证号码'))
    } else {
      callback()
    }
  }
  var checkDate = function(val) {
    var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/
    if (pattern.test(val)) {
      var year = val.substring(0, 4)
      var month = val.substring(4, 6)
      var date = val.substring(6, 8)
      var date2 = new Date(year + '-' + month + '-' + date)
      if (date2 && date2.getMonth() == parseInt(month) - 1) {
        callback()
      }
    }
    callback(new Error('请输入正确的身份证号码'))
  }
  var checkProv = function(val) {
    var pattern = /^[1-9][0-9]/
    var provs = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北 ',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏 ',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门'
    }
    if (pattern.test(val)) {
      if (provs[val]) {
        callback()
      }
    }
    callback(new Error('请输入正确的身份证号码'))
  }
  if (checkCode(value)) {
    var date = value.substring(6, 14)
    if (checkDate(date)) {
      if (checkProv(value.substring(0, 2))) {
        callback()
      }
    }
  } else {
    callback(new Error('请输入正确的身份证号码'))
  }
}
// 邮箱验证
export function validateEMail(rule, value, callback) {
  const reg = /^[0-9a-zA-Z]+@(([0-9a-zA-Z]+)[.])+[a-z]{2,4}$/i
  if (value == '' || value == undefined || value == null) {
    callback()
  } else {
    if (!reg.test(value)) {
      callback(new Error('请输入正确的邮箱地址'))
    } else {
      callback()
    }
  }
}
// 验证是否是数字+字母
export function number(rule, value, callback) {
  const reg = /^(\d|[a-zA-Z])*((\d[a-zA-Z])|([a-zA-Z]\d))(\d|[a-zA-Z])*$/
  if (!reg.test(value) || value.length <= 5) {
    callback(new Error('应该为6-18位包括数字、字母的组合'))
  } else {
    callback()
  }
}

// 验证数字输入框最大数值,32767
export function checkMaxVal(rule, value, callback) {
  if (value < 0 || value > 327670000000000) {
    callback(new Error('请输入数字'))
  } else {
    callback()
  }
}
var validatePass = (rule, value, callback) => {
  if (value !== this.resetword.newPwd) {
    callback(new Error('两次密码不一致'))
  } else {
    callback()
  }
}
// 验证是否整数
export function isInteger(rule, value, callback) {
  if (!value) {
    return callback(new Error('输入不可以为空'))
  }
  setTimeout(() => {
    if (!Number(value)) {
      callback(new Error('请输入正整数'))
    } else {
      const re = /^[0-9]*[1-9][0-9]*$/
      const rsCheck = re.test(value)
      if (!rsCheck) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }
  }, 0)
}
// 验证是数字
export function isNum(rule, value, callback) {
  if (value == '0') {
    callback()
  }
  setTimeout(() => {
    var re = /^(-|\+)?\d+(\.\d+)?$/
    if (!re.test(value)) {
      callback(new Error('必须是数字'))
    } else {
      if (value < -1) {
        callback(new Error('必须是整数'))
      } else {
        callback()
      }
    }
  }, 100)
}

//验证银行卡号
export function isBankCount(rule, bankno, callback) {
  if (bankno.length <= '10') {
    callback(new Error('银行卡号输入不正确'))
  } else {
    var lastNum = bankno.substr(bankno.length - 1, 1) //取出最后一位（与luhn进行比较）
    var first15Num = bankno.substr(0, bankno.length - 1) //前15或18位
    var newArr = new Array()
    for (var i = first15Num.length - 1; i > -1; i--) {
      //前15或18位倒序存进数组
      newArr.push(first15Num.substr(i, 1))
    }
    var arrJiShu = new Array() //奇数位*2的积 <9
    var arrJiShu2 = new Array() //奇数位*2的积 >9
    var arrOuShu = new Array() //偶数位数组
    for (var j = 0; j < newArr.length; j++) {
      if ((j + 1) % 2 == 1) {
        //奇数位
        if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2)
        else arrJiShu2.push(parseInt(newArr[j]) * 2)
      } //偶数位
      else arrOuShu.push(newArr[j])
    }

    var jishu_child1 = new Array() //奇数位*2 >9 的分割之后的数组个位数
    var jishu_child2 = new Array() //奇数位*2 >9 的分割之后的数组十位数
    for (var h = 0; h < arrJiShu2.length; h++) {
      jishu_child1.push(parseInt(arrJiShu2[h]) % 10)
      jishu_child2.push(parseInt(arrJiShu2[h]) / 10)
    }

    var sumJiShu = 0 //奇数位*2 < 9 的数组之和
    var sumOuShu = 0 //偶数位数组之和
    var sumJiShuChild1 = 0 //奇数位*2 >9 的分割之后的数组个位数之和
    var sumJiShuChild2 = 0 //奇数位*2 >9 的分割之后的数组十位数之和
    var sumTotal = 0
    for (var m = 0; m < arrJiShu.length; m++) {
      sumJiShu = sumJiShu + parseInt(arrJiShu[m])
    }

    for (var n = 0; n < arrOuShu.length; n++) {
      sumOuShu = sumOuShu + parseInt(arrOuShu[n])
    }

    for (var p = 0; p < jishu_child1.length; p++) {
      sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p])
      sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p])
    }
    //计算总和
    sumTotal =
      parseInt(sumJiShu) +
      parseInt(sumOuShu) +
      parseInt(sumJiShuChild1) +
      parseInt(sumJiShuChild2)

    //计算luhn值
    var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10
    var luhn = 10 - k
    if (lastNum == luhn) {
      callback()
    } else {
      callback(new Error('银行卡号输入不正确'))
    }
  }
}

/*******
 * @description:
 * @param {*} val
 * @return Bool
 */
function isNumber(val) {
  if (parseFloat(val).toString() == 'NaN') {
    return false
  } else {
    return true
  }
}
