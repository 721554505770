/*
 * @Author: 13188887862 740100750@qq.com
 * @Date: 2022-12-05 10:36:38
 * @LastEditors: 小王最强 wq207430@163.com
 * @LastEditTime: 2023-11-13 14:17:05
 * @FilePath: /CRM2.0-VUE/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * @Author: 李明
 * @Date: 2021-11-03 20:00:29
 * @LastEditTime: 2022-05-04 15:59:16
 * @LastEditors: 李明
 * @Description:
 * @FilePath: /CRM2.0-VUE/src/main.js
 * @@山东壹零贰肆信息科技有限公司
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/global.css'
import '@/plugin'
import store from '@/store'
import axios from 'axios'
import VueQuillEditor from 'vue-quill-editor'
// 导入富文本编辑器样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as filters from '@/filters'
import preventReClick from './api/preventReClick' //防多次点击，重复提交
Vue.use(preventReClick)

// 全局注册富文本编辑器
Vue.use(VueQuillEditor)
// 全局注册 sleect选择器
import directives from './directives'
// 全局注册指令
Vue.use(directives)

//新的富文本编辑器
import VueTinymce from '@packy-tang/vue-tinymce'
Vue.use(VueTinymce)

import ZmTreeOrg from 'zm-tree-org'
import 'zm-tree-org/lib/zm-tree-org.css'

Vue.use(ZmTreeOrg)

// 引入汉字转拼音
import pinyin from 'js-pinyin'
Vue.use(pinyin)

// 注册权限
import comm from '@/api/axios.js'
Vue.prototype.$comm = comm
// 注册消息提示
// import message from "@/api/axios";
// Vue.prototype.$message = message;

if (process.env.VUE_APP_URL == 'jc-shang') {
  document.title = '锦程Crm管理系统'
} else if (process.env.VUE_APP_URL == 'hx-shang') {
  document.title = '华鑫Crm管理系统'
} else if (process.env.VUE_APP_URL == 'zq-xia') {
  document.title = '丰石客户CRM系统'
} else if (process.env.VUE_APP_URL == 'jc-xia') {
  document.title = '本地测试CRM系统'
} else if (process.env.VUE_APP_URL == 'jc-ceshi') {
  document.title = '本地测试CRM系统'
} else if (process.env.VUE_APP_URL == 'local-host') {
  document.title = '电脑测试CRM系统'
} else if (process.env.VUE_APP_URL == 'gy-shang') {
  document.title = '国邦盛世'
} else if (process.env.VUE_APP_URL == 'jn-fs') {
  document.title = '丰晟Crm管理系统'
} else if (process.env.VUE_APP_URL == 'tc-shang') {
  document.title = '天成投研Crm管理系统'
} else if (process.env.VUE_APP_URL == 'jc-zyxx') {
  document.title = '中炎信息Crm管理系统'
}
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 全局表格滚动加载
import elTableInfiniteScroll from 'el-table-infinite-scroll'

Vue.use(elTableInfiniteScroll)

Vue.prototype.$http = axios
Vue.config.productionTip = false

// 让localStorage值无法修改
// window.addEventListener('storage', function(e) {
//   undefined
//   localStorage.setItem(e.key, e.oldValue)
// })

// 引入div拖拽
import './utils/rect'

// 引入icon图标
import './assets/icon/iconfont.css'
// 当localStorage值被修改的时候清除他并且跳转到登录页
// window.addEventListener('storage', function() {
//   localStorage.clear()
//   window.location.replace('/#/login')
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
