/**
 * @Author: 李明
 * @Date: 2021-11-04 09:07:36
 * @LastEditTime: 2021-12-13 11:25:52
 * @LastEditors: 李明
 * @Description:
 * @FilePath: \项目\锦程Crm\锦程crm\src\api\login.js
 * @@山东壹零贰肆信息科技有限公司
 */
import axios from '@/utils/axios'

//用户登录
export function login(data) {
  return axios({
    url: 'login/CrmLogin',
    method: 'post',
    data: data
  })
}
//   返回用户的权限列表
export function ReturnList() {
  return axios({
    url: 'admin/WorkerPowerList',
    method: 'post'
  })
}
//   发送短信接口
export function shortMessage(data) {
  return axios({
    url: 'login/SendCode',
    method: 'post',
    data: data
  })
}
//   根据验证码去修改密码
export function SendCode(data) {
  return axios({
    url: 'login/SendCode',
    method: 'post',
    data: data
  })
}

//   修改密码
export function changePassword(data) {
  return axios({
    url: 'login/changePassword',
    method: 'post',
    data: data
  })
}
//   弹窗提示
export function queryOrderNum(data) {
  return axios({
    url: 'order/queryOrderNum',
    method: 'post',
    data: data
  })
}
