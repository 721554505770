/*
 * @Author: 小王最强 wq207430@163.com
 * @Date: 2022-12-05 10:36:52
 * @LastEditors: 小王最强 wq207430@163.com
 * @LastEditTime: 2023-08-03 10:26:50
 * @FilePath: /CRM2.0-VUE/src/utils/token.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * @Author: 李明
 * @Date: 2021-11-04 09:07:36
 * @LastEditTime: 2022-08-08 20:41:17
 * @LastEditors: 李明
 * @Description:
 * @FilePath: /CRM2.0-VUE/src/utils/token.js
 * @@山东壹零贰肆信息科技有限公司
 */
import basesURL from '@/api/basesURL'
const {
  hxBaseURL,
  gyBaseURL,
  jcBaseURL,
  fsBaseURL,
  bdBaseURL,
  ceshiBaseURL,
  localHostURL,
  tcBaseURL,
  jnfsBaseURL,
  zyxxBaseURL
} = basesURL
//设置token
function setToken(token) {
  localStorage.setItem('_token', token)
}
// //获取token
function getToken() {
  return localStorage.getItem('_token')
}
// //删除token
function removeToken() {
  localStorage.removeItem('_token')
}

const URL = {
  // 华鑫线上
  'hx-shang': hxBaseURL + 'file/uploadImgFromUserApi',
  //   国逸
  'gy-shang': gyBaseURL + 'file/uploadImgFromUserApi',
  // 锦程线上
  'jc-shang': jcBaseURL + 'file/uploadImgFromUserApi',
  // 本地测试
  'jc-xia': bdBaseURL + 'file/uploadImgFromUserApi',
  // 本地测试
  'jc-ceshi': ceshiBaseURL + 'file/uploadImgFromUserApi',
  // 丰石
  'zq-xia': fsBaseURL + 'file/uploadImgFromUserApi',
  // 济南丰晟
  'jn-fs': jnfsBaseURL + 'file/uploadImgFromUserApi',
  // 天成投研
  'tc-shang': tcBaseURL + 'file/uploadImgFromUserApi',
  // 本地电脑测试
  'local-host': localHostURL + 'file/uploadImgFromUserApi',
  // 中炎信息
  'jc-zyxx': zyxxBaseURL + 'file/uploadImgFromUserApi'
  // 逸盛
  // 'ys-shang': 'https://api.10010.1024xxkj.com/api/'
}
const GOODSURL = {
  // 华鑫线上
  'hx-shang': hxBaseURL + 'shopupload/UploadImage',
  //   国逸
  'gy-shang': gyBaseURL + 'shopupload/UploadImage',
  // 锦程线上
  'jc-shang': jcBaseURL + 'shopupload/UploadImage',
  // 本地测试
  'jc-xia': bdBaseURL + 'shopupload/UploadImage',
  // 本地测试
  'jc-ceshi': ceshiBaseURL + 'shopupload/UploadImage',
  // 丰石
  'zq-xia': fsBaseURL + 'shopupload/UploadImage',
  // 济南丰晟
  'jn-fs': jnfsBaseURL + 'shopupload/UploadImage',
  // 天成投研
  'tc-shang': tcBaseURL + 'shopupload/UploadImage',
  // 本地电脑测试
  'local-host': localHostURL + 'shopupload/UploadImage',
  // 中炎信息
  'jc-zyxx': zyxxBaseURL + 'shopupload/UploadImage'
  // 逸盛
  // 'ys-shang': 'https://api.10010.1024xxkj.com/api/'
}
const activeURL = {
  // 华鑫线上
  'hx-shang': hxBaseURL + 'Documents/UploadImage',
  //   国逸
  'gy-shang': gyBaseURL + 'Documents/UploadImage',
  // 锦程线上
  'jc-shang': jcBaseURL + 'Documents/UploadImage',
  // 本地测试
  'jc-xia': bdBaseURL + 'Documents/UploadImage',
  // 本地测试
  'jc-ceshi': ceshiBaseURL + 'Documents/UploadImage',
  // 丰石
  'zq-xia': fsBaseURL + 'Documents/UploadImage',
  // 济南丰晟
  'jn-fs': jnfsBaseURL + 'Documents/UploadImage',
  // 天成投研
  'tc-shang': tcBaseURL + 'Documents/UploadImage',
  // 本地电脑测试
  'local-host': localHostURL + 'Documents/UploadImage',
  // 中炎信息
  'jc-zyxx': zyxxBaseURL + 'Documents/UploadImage'
  // 逸盛
  // 'ys-shang': 'https://api.10010.1024xxkj.com/api/'
}
const videoURL = {
  // 华鑫线上
  'hx-shang': hxBaseURL + 'worker/video/uploadVideo',
  //   国逸
  'gy-shang': gyBaseURL + 'worker/video/uploadVideo',
  // 锦程线上
  'jc-shang': jcBaseURL + 'worker/video/uploadVideo',
  // 本地测试
  'jc-xia': bdBaseURL + 'worker/video/uploadVideo',
  // 本地测试
  'jc-ceshi': ceshiBaseURL + 'worker/video/uploadVideo',
  // 丰石
  'zq-xia': fsBaseURL + 'worker/video/uploadVideo',
  // 济南丰晟
  'jn-fs': jnfsBaseURL + 'worker/video/uploadVideo',
  // 天成投研
  'tc-shang': tcBaseURL + 'worker/video/uploadVideo',
  // 本地电脑测试
  'local-host': localHostURL + 'worker/video/uploadVideo',
  // 中炎信息
  'jc-zyxx': zyxxBaseURL + 'worker/video/uploadVideo'
  // 逸盛
  // 'ys-shang': 'https://api.10010.1024xxkj.com/api/'
}
const upimageURL = URL[process.env.VUE_APP_URL]
const upgoodsimageURL = GOODSURL[process.env.VUE_APP_URL]
const uploadImageUrl = URL[process.env.VUE_APP_URL]
const uploadvideoUrl = videoURL[process.env.VUE_APP_URL]

const activemageURL = activeURL[process.env.VUE_APP_URL]
const token = getToken()
// imageURL: 'https://bd.php.huaxinestates.com/storage/',
// function computer(nums) {
//     if (localStorage.getItem('token')) {
//         return this.$store.getters.computer.indexOf(nums) != -1
//     } else {
//         return this.$store.getters.computer.indexOf(nums) == -1
//     }
// }
export {
  token,
  setToken,
  getToken,
  removeToken,
  // lineimageURL,
  activemageURL,
  upimageURL,
  upgoodsimageURL,
  uploadImageUrl,
  uploadvideoUrl
}
