/*
 * @Author: 13188887862 740100750@qq.com
 * @Date: 2022-12-05 10:36:38
 * @LastEditors: 小王最强 wq207430@163.com
 * @LastEditTime: 2024-03-18 17:06:29
 * @FilePath: /CRM2.0-VUE/src/api/basesURL.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * @Author: 李明
 * @Date: 2022-05-06 10:47:48
 * @LastEditTime: 2022-05-06 10:58:35
 * @LastEditors: 李明
 * @Description:
 * @FilePath: /CRM2.0-VUE/src/api/basesURL.js
 * @@山东壹零贰肆信息科技有限公司
 */
// 华鑫线上
const hxBaseURL = 'https://hx.api.1024xxkj.com/api/'
// 国邦盛世
const gyBaseURL = 'https://gbapi.1024xxkj.com/api/'
// 锦程线上
const jcBaseURL = 'https://api.jc.1024xxkj.com/api/'
// 本地测试
const bdBaseURL = 'https://bd.php.huaxinestates.com/api/'
// 本地测试
const ceshiBaseURL = 'https://ceapi.1024xxkj.com/api/'
// 深圳丰石
const fsBaseURL = 'https://fsa.1024xxkj.com/api/'
// 济南丰晟
const jnfsBaseURL = 'https://apifs.1024xxkj.com/api/'
// 天成投研
const tcBaseURL = 'https://tcty.1024xxkj.com/api/'
// 本地电脑
const localHostURL = 'http://127.0.0.1:8000/api/'
// 中炎信息
const zyxxBaseURL = 'https://zyxxapi.1024xxkj.com/api/'

export default {
  hxBaseURL,
  gyBaseURL,
  jcBaseURL,
  bdBaseURL,
  ceshiBaseURL,
  fsBaseURL,
  jnfsBaseURL,
  tcBaseURL,
  localHostURL,
  zyxxBaseURL
}
